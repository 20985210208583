<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<wastenotifications-form-header :modelPk="modelPk"></wastenotifications-form-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#wastenotifications'">{{ $t('wastenotifications.maintab') }}</v-tab>
				<v-tab :href="'#wastenotificationsitems'" v-if="!isCreatingElement">{{ $t('wastenotifications.wastenotificationsitemstab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'wastenotifications'">
					<v-row dense style="margin-top: -5px" class="pui-form-layout">
						<v-col cols="12">
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									v-if="!this.isCreatingElement"
									:id="`wastenotifications-portcall`"
									attach="wastenotifications-portcall"
									:label="$t('wastenotifications.portcall')"
									toplabel
									clearable
									:disabled="!this.isCreatingElement || this.$route.params.parentpk"
									v-model="model"
									modelName="vlupportcalls"
									:modelFormMapping="{ portcallid: 'portcallid' }"
									:itemsToSelect="[{ portcallid: model.portcallid }]"
									:itemValue="['portcallid']"
									:itemText="(item) => `${item.visitid}`"
									:order="{ portcallid: 'desc' }"
									:fixedFilter="filterPortcalls"
								></pui-select>
								<pui-select
									v-if="this.isCreatingElement"
									:id="`wastenotifications-portcall`"
									attach="wastenotifications-portcall"
									:label="$t('wastenotifications.portcall')"
									toplabel
									clearable
									:disabled="!this.isCreatingElement || this.$route.params.parentpk"
									v-model="model"
									modelName="vlupportcalls"
									:modelFormMapping="{ portcallid: 'portcallid' }"
									:itemsToSelect="[{ portcallid: model.portcallid }]"
									:itemValue="['portcallid']"
									:itemText="(item) => `${item.visitid}`"
									:order="{ portcallid: 'desc' }"
									:fixedFilter="filterPortcalls"
								></pui-select>
							</v-col>
							<pui-field-set :title="$t('wastenotifications.ship')">
								<v-row dense>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`wastenotifications-shipname`"
											v-model="model.portcall.mship.shipname"
											:label="$t('wastenotifications.shipname')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>

									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`wastenotifications-imo`"
											v-model="model.portcall.mship.imo"
											:label="$t('wastenotifications.imo')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-number-field
											:id="`wastenotifications-groostonage`"
											v-model="model.portcall.mship.grosstonnage"
											:label="$t('wastenotifications.groostonage')"
											disabled
											toplabel
											min="1"
										></pui-number-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`imofalform6menu-shiptype`"
											attach="imofalform6menu-shiptype"
											:label="$t('imofalform6menu.shiptype')"
											toplabel
											clearable
											disabled
											reactive
											v-model="model.portcall.mship"
											modelName="mshiptypes"
											:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
											:itemsToSelect="itemsToSelectShipType"
											:itemValue="['shiptypecode']"
											:itemText="(item) => ` ${item.shiptypecode}, ${item.shiptypedesc}`"
											:order="{ shiptypedesc: 'asc' }"
										></pui-select>
									</v-col>

									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`wastenotifications-shipagentname`"
											v-model="model.portcall.shipagendname"
											:label="$t('wastenotifications.shipagentname')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`wastenotifications-callsign`"
											v-model="model.portcall.mship.callsign"
											:label="$t('wastenotifications.callsign')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`wastenotifications-flag`"
											attach="wastenotifications-flag"
											:label="$t('wastenotifications.flag')"
											toplabel
											disabled
											clearable
											reactive
											v-model="model.portcall"
											modelName="mcountries"
											:modelFormMapping="{ countrycode: 'countrycode' }"
											:itemsToSelect="itemsToSelectFlag"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
											:order="{ countrydesc: 'asc' }"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>
							<pui-field-set :title="$t('wastenotifications.voyage')">
								<v-row dense style="margin-top: -5px">
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<!--
										<pui-text-field
											:id="`ETA-wastenotifications`"
											v-model="model.portcall.eta"
											:label="$t('wastenotifications.ETA')"
											disabled
											toplabel
										></pui-text-field>
											-->
										<pui-date-field
											:label="$t('wastenotifications.ETA')"
											v-model="model.portcall.eta"
											disabled
											toplabel
											time
										></pui-date-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<!--
										<pui-text-field
											:id="`ETD-wastenotifications`"
											v-model="model.portcall.etd"
											:label="$t('wastenotifications.ETD')"
											disabled
											toplabel
										></pui-text-field>
										-->
										<pui-date-field
											:label="$t('wastenotifications.ETD')"
											v-model="model.portcall.etd"
											disabled
											toplabel
											time
										></pui-date-field>
									</v-col>
									<!-- TOBEDISPOSED -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`tobedisposed-wastenotifications`"
											:label="$t('wastenotifications.tobedisposed')"
											:attach="`tobedisposed-wastenotifications`"
											itemText="text"
											itemValue="value"
											toplabel
											:disabled="this.model.status == 2 || this.model.status == 3"
											v-model="model"
											:items="codes"
											reactive
											required
											clearable
											:itemsToSelect="toBeDisposedToSelect"
											:modelFormMapping="{ value: 'tobedisposed' }"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<!--
										<pui-text-field
											:id="`previousport-wastenotifications`"
											v-model="model.portcall.previousport"
											:label="$t('wastenotifications.previousport')"
											disabled
											toplabel
										></pui-text-field>
										-->
										<pui-select
											id="`lastport-wastenotifications`"
											attach="lastport-wastenotifications"
											:label="$t('wastenotifications.lastport')"
											toplabel
											disabled
											reactive
											clearable
											v-model="model.portcall.mship"
											modelName="mports"
											:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
											:itemsToSelect="itemsToSelectRegistryPort"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-col>
									<!-- LASTDELIVERYPORT -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`lastdeliveryport-wastenotifications`"
											attach="wastenotifications-lastdeliveryport"
											:label="$t('wastenotifications.lastdeliveryport')"
											toplabel
											v-model="model"
											modelName="mports"
											reactive
											required
											:disabled="this.model.status == 2 || this.model.status == 3"
											:modelFormMapping="{ countrycode: 'lastdeliverycountry', portcode: 'lastdeliveryport' }"
											:itemsToSelect="itemsToSelectlastdeliveryPort"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-col>
									<!-- LASTDELIVERYDATE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-date-field
											:label="$t('wastenotifications.lastdeliverydate')"
											v-model="model.lastdeliverydate"
											:disabled="this.model.status == 2 || this.model.status == 3"
											toplabel
											required
											:max="new Date()"
										></pui-date-field>
									</v-col>
									<!-- NEXTDELIVERYPORT -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`nextdeliveryport-wastenotifications`"
											attach="wastenotifications-nextdeliveryport"
											:label="$t('wastenotifications.nextdeliveryport')"
											toplabel
											v-model="model"
											modelName="mports"
											reactive
											required
											:disabled="this.model.status == 2 || this.model.status == 3"
											:modelFormMapping="{ countrycode: 'nextdeliverycountry', portcode: 'nextdeliveryport' }"
											:itemsToSelect="itemsToSelectnextdeliveryPort"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :value="'wastenotificationsitems'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="wastenotificationitems-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ notificationid: 'notificationid' }"
						:parentModel="model"
						:formDisabled="this.model.status === 2 || this.model.status === 3"
					></pui-master-detail>
				</v-tab-item>
				<!-- Page Footer -->
				<pui-form-footer>
					<nsw-imo-form-footer-btns
						:formDisabled="!havePermissions(['WRITE_WASTENOTIFICATIONS'])"
						:saveDisabled="saving"
						:value="model"
						:saveAndUpdate="saveAndUpdate"
						:showNext="isCreatingElement"
						:showNewVersion="false"
						modelName="wastenotifications"
						:save="save"
						:back="back"
						:showDraft="!isCreatingElement && this.model.status !== 2"
						:showSubmit="!isCreatingElement && this.model.status !== 2"
					></nsw-imo-form-footer-btns>
				</pui-form-footer>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import wastenotificationsActions from './WastenotificationsActions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';
import { STATUS_SUBMITTED_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';

export default {
	name: 'wastenotifications-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'wastenotifications',
			pkAttribute: 'notificationid',
			actions: wastenotificationsActions.actions,
			actionsMasterDetail: wastenotificationsActions.actionsMasterDetail,
			statusSubmitted: STATUS_SUBMITTED_DESC,
			codes: [
				{
					text: this.$t('wastenotifications.all'),
					value: 'All'
				},
				{
					text: this.$t('wastenotifications.some'),
					value: 'Some'
				},
				{
					text: this.$t('wastenotifications.none'),
					value: 'None'
				}
			]
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'wastenotification');
			}
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}
		},
		fillPortCallFields(lupaModel) {
			this.model.portcall.shipagendname = lupaModel.owneroperator;
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.countrycode = lupaModel.countrycode;

			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.eta = lupaModel.eta;
			this.model.portcall.etd = lupaModel.etd;

			this.model.portcall.mship.grosstonnage = lupaModel.grosstonnage;
			this.model.portcall.mship.certregistrycountry = lupaModel.certregistrycountry;
			this.model.portcall.mship.certregistryport = lupaModel.certregistryport;
		},
		clearPortCallFields() {
			this.model.portcall.etd = null;
			this.model.portcall.eta = null;
			this.model.portcall.shipagendname = null;
			this.model.portcall.mship.grosstonnage = null;
			this.model.portcall.mship.shipname = null;
			this.model.portcall.mship.shiptypecode = null;
			this.model.portcall.mship.imo = null;
			this.model.portcall.mship.callsign = null;
			this.model.portcall.voyagenum = null;
			this.model.portcall.countryofarrival = null;
			this.model.portcall.countryofdeparture = null;
			this.model.portcall.portofarrival = null;
			this.model.portcall.portofdeparture = null;
			this.model.arrivaldate = null;
			this.model.departuredate = null;
			this.model.portcall.countrycode = null;
			this.model.portcall.portcode = null;
			this.model.portcall.previousport = null;
		}
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-wastenotifications-portcall', (newVal) => {
			var lupaModel = newVal.model;

			if (lupaModel !== null) {
				this.fillPortCallFields(lupaModel);
			} else {
				this.clearPortCallFields();
			}
		});
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		},
		toBeDisposedToSelect() {
			return [{ value: this.model.tobedisposed }];
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.portcall.countrycode }];
		},
		itemsToSelectlastdeliveryPort() {
			return [{ countrycode: this.model.lastdeliverycountry, portcode: this.model.lastdeliveryport }];
		},
		itemsToSelectnextdeliveryPort() {
			return [{ countrycode: this.model.nextdeliverycountry, portcode: this.model.nextdeliveryport }];
		},
		itemsToSelectRegistryPort() {
			return [
				{
					countrycode: this.model.portcall.previouscountry,
					portcode: this.model.portcall.previousport
				}
			];
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.portcall.mship.shiptypecode }];
		}
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-wastenotifications-portcall');
	},
	created() {}
};
</script>
